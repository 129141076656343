import axios from 'axios'
import JwtService from '@/auth/jwt/jwtService'

export default class RequestAdmin {
  // eslint-disable-next-line class-methods-use-this
  async getArraySelect(url) {
    try {
      const data = await this.executeGet(url)
      if (data === null) return []
      const array = []
      data.forEach(item => {
        array.push({ text: item.Nombre, value: item.Id })
      })
      return array
    } catch (error) {
      console.error('error executeGet', error)
      return []
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async executeGet(url) {
    try {
      const jwt = new JwtService()
      const token = await jwt.getToken()
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return resp.data
    } catch (error) {
      console.error('error executeGet', error)
      return null
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async executePostPutPatch(url, body, method) {
    try {
      const jwt = new JwtService()
      const token = await jwt.getToken()
      if (method === 'POST') {
        const response = await axios.post(
          url, body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        return response.data
      }
      if (method === 'PATCH') {
        const response = await axios.patch(
          url, body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        return response.data
      }
      const response = await axios.put(
        url, body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      return response.data
    } catch (error) {
      console.error('error executePostPut', error)
      return null
    }
  }
}
