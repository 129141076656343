import RequestAdmin from '@/requests/request'

const url = process.env.VUE_APP_LOGIN_URL

// eslint-disable-next-line import/prefer-default-export
export async function logon(user, password) {
  try {
    const request = new RequestAdmin()
    return request.executePostPutPatch(url, { email: user, password }, 'POST')
  } catch (error) {
    console.error('error logon', error)
    return null
  }
}
